import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import the theme
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import ProjectsPage from './pages/ProjectsPage';
import RatesPage from './pages/RatesPage';
import ContactPage from './pages/ContactPage';
import Header from './components/Header';
import Footer from './components/Footer';
import ConstructionPage from './pages/ConstructionPage';
import ITPage from './pages/ITPage';
import ConsultingPage from './pages/ConsultingPage'; // You will create similar files for other services
import DefensePage from './pages/DefensePage';
import EnergyPage from './pages/EnergyPage';
import SupplyChainPage from './pages/SupplyChainPage';
import './styles.css'; // Global styles

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/services/construction" element={<ConstructionPage />} />
            <Route path="/services/it" element={<ITPage />} />
            <Route path="/services/consulting" element={<ConsultingPage />} />
            <Route path="/services/defense" element={<DefensePage />} />
            <Route path="/services/energy" element={<EnergyPage />} />
            <Route path="/services/supplychain" element={<SupplyChainPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/rates" element={<RatesPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
