import React from 'react';
import { Box, Typography, Container, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ServiceCard from '../components/ServiceCard';
import ProjectCaseStudy from '../components/ProjectCaseStudy';

// Image Import for Services
import hero from '../assets/hero.png';
import service1 from '../assets/construction.png'; // Import the logo
import service2 from '../assets/it_security_services.png'; // Import the logo
import service3 from '../assets/consulting.png'; // Import the logo
import service4 from '../assets/aerospace_contracting.png'; // Import the logo
import service5 from '../assets/environmental_services.png'; // Import the logo
import service6 from '../assets/supply_chain_management.png'; // Import the logo
import LMS from '../assets/lms.png'; // Import the logo
import ScalableNetwork from '../assets/scaleablenetwork.png'; // Import the logo

function HomePage() {
  return (
    <>
    <Box className="hero-section"
            sx={{
              backgroundImage: `url(${hero})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '100px 0',
              color: 'white',
              textAlign: 'center',
            }}
    >
      <Typography variant="h1" gutterBottom>
        Building the Future with Integrity and Excellence
      </Typography>
      <Typography variant="h5" paragraph sx={{ color: 'white'}}>
        TRW Contracting Group delivers world-class contracting services in construction, IT, and more. Partner with us for your next project.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        component={Link} 
        to="/services"
      >
        Explore Our Services
      </Button>
    </Box>
          {/* Services Section */}
          <Box sx={{ padding: '50px 0', backgroundColor: '#f9f9f9' }}>
          <Container>
            <Typography variant="h4" align="center" gutterBottom>
              Our Services
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              We offer a wide range of services to meet your needs.
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCard
                title="Construction & Infrastructure Development"
                description="Providing large-scale construction services including federal facilities, roads, and infrastructure for public projects."
                  imageUrl={service1}
                  linkUrl="/services/construction"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCard
                  title="IT & Cybersecurity Services"
                  description="Providing cutting-edge IT solutions and secure cyber infrastructure for government operations, including cloud services and custom software development."
                  imageUrl={service2}
                  linkUrl="/services/it"
                  sx={{ textTransform: 'none' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCard
                  title="Consulting & Strategic Advisory"
                  description="Helping government agencies strategize and manage large-scale projects with efficiency, including defense and civilian infrastructure consulting."
                  imageUrl={service3}
                  linkUrl="/services/consulting"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCard
                  title="Defense & Aerospace Contracting"
                  description="Supporting national defense through manufacturing, R&D, and technology solutions, with a focus on aerospace and military operations."
                  imageUrl={service4}
                  linkUrl="/services/defense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCard
                  title="Energy & Environmental Services"
                  description="Providing sustainable energy solutions and environmental management, including solar installations and green building for federal projects."
                  imageUrl={service5}
                  linkUrl="/services/energy"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceCard
                  title="Supply Chain Management"
                  description="Optimized supply chain management services for simple and complex projects."
                  imageUrl={service6}
                  linkUrl="/services/supplychain"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
  
        {/* Projects Section */}
        <Box sx={{ padding: '50px 0', backgroundColor: '#fff' }}>
          <Container>
            <Typography variant="h4" align="center" gutterBottom>
              Featured Projects
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Take a look at some of our recent successful projects.
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <ProjectCaseStudy
            title="Leaning Management System Project"
            description="Developed a comprehensive Learning System covering multiple subjects and levels of study, while also creating the content and building a system to manage access and track the delivery of training."
            imageUrl={LMS}                
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ProjectCaseStudy
            title="Secure Scalable Network Project"
            description="Delivered a scalable network and security solution that not only met the contract requirements but also exceeded security standards, ensuring the systems were protected from unauthorized alterations or threats."
            imageUrl={ScalableNetwork}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
  
        {/* Call-to-Action Section */}
        <Box sx={{ padding: '50px 0', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
          <Container>
            <Typography variant="h4" align="center" gutterBottom>
              Ready to Work with Us?
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Contact us today to get started on your next big project.
            </Typography>
            <Button variant="contained" color="primary" size="large" href="/contact">
              Contact Us
            </Button>
          </Container>
        </Box>
        </>
  );
}

export default HomePage;
