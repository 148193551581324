import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box sx={{ bgcolor: '#333333', color: '#ffffff', py: 4 }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={4}>
        <Typography variant="h6">© {new Date().getFullYear()} TRW Contracting Group</Typography>
<Typography variant="body2" paragraph>
  A TRW Holdings, LLC Company
</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">Quick Links</Typography>
          <Button component={Link} to="/" color="inherit" underline="hover">Home</Button><br />
          <Button color="inherit" component={Link} to="/services">Services</Button><br />
          <Button color="inherit" component={Link} to="/projects">Projects</Button><br />
          <Button color="inherit" component={Link} to="/rates">Rates</Button><br />
          <Button color="inherit" component={Link} to="/contact">Contact</Button><br />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">Contact Information</Typography>
          <Typography variant="body2">
            7305 SW Pecan Meadow Dr <br />
            Phone: (909) 801-4282 <br />
            Email: <Link href="mailto:info@trwcontractinggroup.com" color="inherit">info@trwcontractinggroup.com</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;