import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ProjectCaseStudy from '../components/ProjectCaseStudy';

import DownTownProject from '../assets/downtownproject.jpeg'; // Import the logo
import ScalableNetwork from '../assets/scaleablenetwork.png'; // Import the logo
import LMS from '../assets/lms.png'; // Import the logo
import VirtaulHelp from '../assets/virtualhelp.png'; // Import the logo


function ProjectsPage() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Our Projects
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        We are proud to showcase our successful projects, which demonstrate our commitment to excellence.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Secure Scalable Network Project"
            description="Delivered a scalable network and security solution that not only met the contract requirements but also exceeded security standards, ensuring the systems were protected from unauthorized alterations or threats."
            imageUrl={ScalableNetwork}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Multi-Family Construction Project"
            description="Managed a large-scale multi-family housing project, completing it ahead of schedule and within budget."
            imageUrl={DownTownProject}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Leaning Management System Project"
            description="Developed a comprehensive Learning System covering multiple subjects and levels of study, while also creating the content and building a system to manage access and track the delivery of training."
            imageUrl={LMS}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Project Apollo"
            description="Developed a virtual system providing immediate psychological support for individuals seeking help from home."
            imageUrl={VirtaulHelp}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProjectsPage;
