import React from 'react';

const AboutPage = () => {
  return (
    <div className="about-trw-contracting">
      <div className="container">
        <section className="about-intro">
          <h1>About TRW Contracting Group</h1>
          <p>
            TRW Contracting Group is a world-class provider of government contracting services, specializing in
            delivering high-quality solutions across multiple industries. Our extensive experience in the fields
            of defense, IT, energy, and supply chain management allows us to execute large-scale projects with
            precision and efficiency.
          </p>
        </section>

        <section className="about-mission">
          <h2>Our Mission</h2>
          <p>
            Our mission is to provide innovative and effective solutions to meet the complex demands of government contracts. 
            We are committed to maintaining the highest standards of professionalism, quality, and integrity, ensuring that 
            we deliver projects on time, on budget, and to the highest level of satisfaction.
          </p>
        </section>

        <section className="about-expertise">
          <h2>Our Expertise</h2>
          <ul>
            <li><strong>Construction:</strong> Managing large-scale construction projects with a focus on safety, quality, and efficiency.</li>
            <li><strong>IT Services:</strong> Offering cutting-edge IT infrastructure, cybersecurity, and technology solutions to meet the evolving needs of our clients.</li>
            <li><strong>Consulting:</strong> Providing expert advice and strategies to enhance project success and streamline operations.</li>
            <li><strong>Supply Chain Management:</strong> Streamlining logistics, procurement, and supply chain operations to optimize efficiency and reduce costs.</li>
            <li><strong>Engineering:</strong> Delivering innovative engineering solutions that drive the success of complex infrastructure and development projects.</li>
            <li><strong>Project Management:</strong> Ensuring seamless project execution through effective planning, coordination, and leadership.</li>
          </ul>
        </section>

        <section className="about-values">
          <h2>Our Core Values</h2>
          <ul>
            <li><strong>Integrity:</strong> We conduct every project with the highest ethical standards.</li>
            <li><strong>Innovation:</strong> We continuously seek new technologies and approaches to solve challenges.</li>
            <li><strong>Excellence:</strong> We strive for excellence in every aspect of our business, from project execution to client satisfaction.</li>
            <li><strong>Commitment:</strong> We are dedicated to delivering projects that exceed expectations and create long-term value.</li>
          </ul>
        </section>

        <section className="about-team">
          <h2>Our Team</h2>
          <p>
            Our team is composed of highly skilled professionals with decades of experience in contracting, engineering, 
            project management, and technology. Each member of TRW Contracting Group is dedicated to ensuring the success 
            of our clients and delivering solutions that meet the evolving needs of the government sector.
          </p>
        </section>

        <section className="about-history">
          <h2>Our Journey</h2>
          <p>
            Established in 2024, TRW Contracting Group has grown from a small operation to a leading provider of
            government contracting services. Our journey has been defined by continuous growth, innovation, and a 
            steadfast commitment to our clients. We have successfully completed numerous large-scale 
            projects and established a reputation for excellence and reliability.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;
