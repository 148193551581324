import React from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Import the logo

function Header() {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <img src={logo} alt="TRW Contracting Group" style={{ height: '50px', marginRight: '10px'}} />
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          TRW Contracting Group
        </Typography>
        <Button color="inherit" component={Link} to="/">Home</Button>
        <Button color="inherit" component={Link} to="/about">About</Button>
                {/* Services Dropdown */}
                <Button component={Link} to="/services" color="inherit" onClick={handleMenuClick}>
          Services
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem component={Link} to="/services/construction">
            Construction & Infrastructure Development
          </MenuItem>
          <MenuItem component={Link} to="/services/it">
            IT & Cybersecurity Services
          </MenuItem>
          <MenuItem component={Link} to="/services/consulting">
            Consulting & Strategic Advisory
          </MenuItem>
          <MenuItem component={Link} to="/services/defense">
            Defense & Aerospace Contracting
          </MenuItem>
          <MenuItem component={Link} to="/services/energy">
            Energy & Environmental Services
          </MenuItem>
          <MenuItem component={Link} to="/services/supplychain">
            Supply Chain Management
          </MenuItem>
        </Menu>
        <Button color="inherit" component={Link} to="/projects">Projects</Button>
        <Button color="inherit" component={Link} to="/rates">Rates</Button>
        <Button color="inherit" component={Link} to="/contact">Contact</Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
