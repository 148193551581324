import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ServiceCard from '../components/ServiceCard';
import service1 from '../assets/construction.png'; // Import the logo
import service2 from '../assets/it_security_services.png'; // Import the logo
import service3 from '../assets/consulting.png'; // Import the logo
import service4 from '../assets/aerospace_contracting.png'; // Import the logo
import service5 from '../assets/environmental_services.png'; // Import the logo
import service6 from '../assets/supply_chain_management.png'; // Import the logo

function ServicesPage() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Our Services
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        We provide a wide range of services to meet the needs of our clients, from construction and engineering to IT solutions and consulting.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Construction" description="Complete construction and infrastructure solutions, from planning to execution." imageUrl={service1} linkUrl="/services/construction" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="IT Solutions" description="Innovative IT services, including cloud computing, cybersecurity, and data solutions." imageUrl={service2} linkUrl="/services/it" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Consulting" description="Strategic consulting services to help clients meet their project goals and objectives." imageUrl={service3} linkUrl="/services/consulting" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Supply Chain Management" description="Optimized supply chain management services for complex projects." imageUrl={service4} linkUrl="/services/defense" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Engineering" description="Full engineering services for infrastructure and systems design." imageUrl={service5} linkUrl="/services/energy" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Program Management" description="Experienced program management services for large-scale projects." imageUrl={service6} linkUrl="/services/supplychain" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServicesPage;
